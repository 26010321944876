import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';

export const useUsersEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('users');

	const Add = () =>
		ep.Post<string>({}).catch(() => {
			useError('Error adding user');
			return undefined;
		});

	return { Add, IsLoading: ep.IsLoading };
};
